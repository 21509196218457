<template>
  <div
    id="modal-out-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-edit-user"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Keluar Halaman
      </div>
      <p style="font-size:15px">
        Semua data tidak akan tersimpan jika kamu keluar dari halaman ini. Apakah kamu yakin ingin keluar?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          @click="goToOut"
        >
          Ya, keluar
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalDelete: 'user/modalDelete'
    })
  },
  methods: {
    ...mapActions({
      deleteUser: 'user/deleteUser'
    }),
    goToOut() {
      window.location = '/admin/user'
    }
  }
}
</script>
